// Import global variables
@import "/src/styles/variables.scss";

.newsLink {
  padding: $spacing--component;
  width: fit-content;
  text-decoration: none;
  color: $brand-color--black;
  :hover {
    color: $brand-color--ocra;
  }
}

.newsTitle {
  @include subTitle;
}
