// Import global variables
@import "/src/styles/variables.scss";

.footer {
  @include centeredFlexColumn;
  justify-content: center;
  width: $full-percent;
  background-color: $brand-color--black;
  color: $brand-color--yellow;
  min-height: $height--footer;

  .terms {
    @include centeredFlexRow;
    padding: $spacing--grid;
    @media (max-width: $breakpoint--tablet) {
      @include centeredFlexColumn;
    }

    a,
    button {
      @include subTitle;
      background-color: inherit;
      margin: 0 $spacing--component;
    }
  }

  .rights {
    @include centeredFlexColumn;
    @include basicText;
    text-align: center;
    padding: $spacing--grid;
    color: $brand-color--grey-light;
    @media (max-width: $breakpoint--tablet) {
      font-size: $font-size--small;
    }
  }
}
