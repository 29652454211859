// Import global variables
@import "/src/styles/variables.scss";

.app {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  color: $brand-color--black;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: $full-percent;
  min-height: calc(
    #{$height--full} - #{$height--footer} - #{$height--header-desktop}
  );
  min-height: -o-calc(
    #{$height--full} - #{$height--footer} - #{$height--header-desktop}
  );
  min-height: -webkit-calc(
    #{$height--full} - #{$height--footer} - #{$height--header-desktop}
  );
  min-height: -moz-calc(
    #{$height--full} - #{$height--footer} - #{$height--header-desktop}
  );
  @media (max-width: $breakpoint--tablet) {
    min-height: calc(
      #{$height--full} - #{$height--footer} - #{$height--header-mobile}
    );
    min-height: -o-calc(
      #{$height--full} - #{$height--footer} - #{$height--header-mobile}
    );
    min-height: -webkit-calc(
      #{$height--full} - #{$height--footer} - #{$height--header-mobile}
    );
    min-height: -moz-calc(
      #{$height--full} - #{$height--footer} - #{$height--header-mobile}
    );
  }
}
