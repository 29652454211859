// Import global variables
@import "/src/styles/variables.scss";

.topImage {
  width: $full-percent;
  height: auto;
  background-color: $brand-color--brown-bg;

  @media (max-width: $breakpoint--header) {
    height: auto;
    text-align: center;
    margin: 0 0;
  }
}

.gemImage {
  padding: $spacing--grid;
  position: absolute;
  bottom: 25%;
  max-width: 100%;
}

.infoButton {
  width: 30px;
  height: auto;
  position: absolute;
  right: 5px;
  top: 5px
}

.magicIcon {
  max-width: 1.3 * $width--container;
}

.logoImage {
  cursor: pointer;
}

.logoImageMobile {
  height: auto;
  width: $spacing--app;
  margin-left: calc(50% - $spacing--app / 2);
  @media (min-width: $breakpoint--tablet) {
    display: none;
  }
}

.hideInMobile {
  @include hideInMobile;
}

.hideInSmallerThanTablet {
  @include hideInSmallerThanTablet;
}

.hideInSmallerThanDesktop {
  @include hideInSmallerThanDesktop;
}

.hideInDesktop {
  @include hideInDesktop;
}

.footerLogo {
  margin: $spacing--component;
}

.playerImg {
  height: 2 * $spacing--component;
  margin: $spacing--grid;
  border-radius: $spacing--grid;
}

.placeholderPlayerImg {
  width: 57px;
  height: 57px;
  margin: $spacing--grid;
}

.gem {
  margin: $spacing--grid;
}
