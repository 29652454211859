$brand-color--white: #ffffff;
$brand-color--brown: #aa4a0a;
$brand-color--brown-dark: #191919;
$brand-color--brown-border: #b17a00;
$brand-color--yellow: #ffda47;
$brand-color--yellow-header: #f7b500;
$brand-color--yellow-text: #f0d16d;
$brand-color--red-text: #701D00;
$brand-color--orange: #aa4a0a;
$brand-color--brown-bg: #3c3835;
$brand-color--brown-shadow: #3c3835;
$brand-color--black: #111111;
$brand-color--grey-light: #ffffff80;
$brand-color--green: #34a628;
$brand-color--green-medium: #62b33f;
$brand-color--green-bright: #73d42f;
$brand-color--blue: #0021d8;
$brand-color--blue-light: #0e55f6;
$brand-color--orange: #fa7800;
$brand-color--ocra: #cd8509;

$font-size--small: 16px;
$font-size--normal: 24px;
$font-size--medium: 30px;
$font-size--large: 44px;
$text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
$spacing--grid: 10px;
$spacing--component: 30px;
$spacing--app: 100px;
$width--container: 600px;
$width--full-viewport: 100vw;
$width--max: 95vw;
$width--card-product: 310px;
$height--full: 100vh;
$height--card-product: 480px;
$height--footer: 380px;
$height--header-mobile: 80px;
$height--header-desktop: 80px;
$full-percent: 100%;

$breakpoint--header: 1370px;
$breakpoint--desktop: 1200px;
$breakpoint--tablet: 990px;
$breakpoint--mobile: 660px;

html,
body {
  background-color: $brand-color--brown-bg;
  @media (max-width: $breakpoint--mobile) {
    text-align: center;
  }
  white-space: pre-line;
}

div {
  color: $brand-color--yellow-text;
}

span {
  font-family: "SofiaSans";
}

button {
  font-family: inherit;
}

@mixin mainTitle {
  font-size: $font-size--large;
  color: $brand-color--yellow-header;
  @media (max-width: $breakpoint--tablet) {
    font-size: $font-size--medium;
  }
}

@mixin subTitle {
  font-size: $font-size--normal;
  color: $brand-color--yellow-header;
  @media (max-width: $breakpoint--tablet) {
    font-size: $font-size--normal;
  }
}

@mixin basicText {
  font-size: $font-size--normal;
  color: $brand-color--yellow-text;
}

@mixin centeredFlexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin centeredFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin basicButton {
  cursor: pointer;
  transition: all 0.25s ease;
  border-radius: 12px;
  font-size: $font-size--medium;
  margin-top: $spacing--grid;
  padding: 14px 60px;
  border: none;

  &:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translateY(-5px);
    filter: brightness(85%);
  }

  &:disabled {
    cursor: not-allowed;
    transform: none;
  }
}

@mixin basicCard {
  padding: $spacing--component 0;
  height: fit-content;
  width: $full-percent;
  background-color: $brand-color--brown-bg;
}

@mixin modalShape {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin plainLink {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@mixin basicInput {
  display: block;
  margin: $spacing--grid 0;
  padding: $spacing--grid;
  font-size: $font-size--normal;
  border: 1px solid $brand-color--black;
  border-radius: 10px;
  box-sizing: border-box;
}

@mixin navLink {
  @include subTitle;
  @include plainLink;
  cursor: pointer;
  border: none;
  text-shadow: 1px 1px rgb(211, 29, 6);
  text-decoration: none;
  color: $brand-color--yellow-header;
  &:hover {
    text-decoration: underline;
  }
}

@mixin componentWidth {
  max-width: $width--container;
  @media (max-width: $breakpoint--mobile) {
    max-width: $width--max;
  }
}

@mixin hideInMobile {
  @media (max-width: $breakpoint--mobile) {
    display: none;
  }
}

@mixin hideInSmallerThanTablet {
  @media (max-width: $breakpoint--tablet) {
    display: none;
  }
}

@mixin hideInSmallerThanDesktop {
  @media (max-width: $breakpoint--desktop) {
    display: none;
  }
}

@mixin hideInDesktop {
  @media (min-width: $breakpoint--desktop) {
    display: none;
  }
}
