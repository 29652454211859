// Import global variables
@import "/src/styles/variables.scss";

.center {
  position: fixed;
  z-index: 100;
  top: calc(50% - #{$spacing--component});
  top: -o-calc(50% - #{$spacing--component});
  top: -webkit-calc(50% - #{$spacing--component});
  top: -moz-calc(50% - #{$spacing--component});
  left: calc(50% - #{$spacing--component});
  left: -o-calc(50% - #{$spacing--component});
  left: -webkit-calc(50% - #{$spacing--component});
  left: -moz-calc(50% - #{$spacing--component});
}

.spinner {
  border: $spacing--grid solid $brand-color--yellow;
  border-top: $spacing--grid solid $brand-color--brown;
  border-radius: 50%;
  width: 2 * $spacing--component;
  height: 2 * $spacing--component;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
