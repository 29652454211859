// Import global variables
@import "/src/styles/variables.scss";

.communityCard {
  @include centeredFlexColumn;
  width: fit-content;
  margin: $spacing--grid;

  img {
    max-width: $width--max;
  }
}

.cards {
  @include centeredFlexRow;
  justify-content: center;
  padding: $spacing--component 0;
  width: $full-percent;
  @media (max-width: $breakpoint--desktop) {
    @include centeredFlexColumn;
  }
}

.infoText {
  font-size: $font-size--normal;
  text-align: center;
  position: relative;
  bottom: 200px;
  height: 0;
  width: $width--card-product + 30;
  margin: $spacing--component;
  link {
    text-decoration: none;
  }
  color: $brand-color--red-text;
}

.picture {
  object-fit: cover;
}