// Import global variables
@import "/src/styles/variables.scss";

.drawerContainer {
  @media (min-width: $breakpoint--tablet) {
    display: none;
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  background: $brand-color--black;
  min-width: 200px;
  height: $height--full;
  position: fixed;
  box-shadow: 0 0 $spacing--grid rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;

  li {
    margin: $spacing--grid 0;
  }

  button {
    margin-bottom: $spacing--app;
  }

  @media (min-width: $breakpoint--tablet) {
    display: none;
  }
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  width: $full-percent;
  height: $full-percent;
  top: 0;
  left: 0;
  z-index: 0;
  position: fixed;
  pointer-events: none;
  @media (min-width: $breakpoint--tablet) {
    display: none;
  }
}

.drawerContainer.open .backdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 2;
}
