// Import global variables
@import "/src/styles/variables.scss";

.gemContainer {
  @include centeredFlexRow;
  justify-content: center;
  flex-wrap: wrap;
  width: fit-content;
  padding-bottom: $spacing--component;
  @media (min-width: $breakpoint--desktop) {
    max-width: 3 * $width--container;
  }
}
