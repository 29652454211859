// Import global variables
@import "/src/styles/variables.scss";

.game {
  transform: scaleY(1) scaleX(1);
  height: 100vh;
  width: auto;
  margin: auto;
  border: none;
  aspect-ratio: 16 / 9;
}

.buttons {
  background: rgb(0,0,0);
  justify-content: right;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: -48px;
  z-index: 10;
}

button {
  margin: $spacing--grid;
}
