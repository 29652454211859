// Import global variables
@import "/src/styles/variables.scss";

.rowCard {
  @include basicCard;
  @include centeredFlexRow;
  max-width: $width--max;
  z-index: 0;
}

.columnCard {
  @include basicCard;
  @include centeredFlexColumn;
  max-width: $width--max;
  z-index: 0;
}

.headerCard {
  z-index: 0;
  @include basicCard;
  @include centeredFlexColumn;
  background-color: rgb(152, 113, 21);
  padding: 0 0;
  margin: -10px 0;
}

.centered {
  justify-content: center;
}

.productCard {
  @include centeredFlexColumn;
  height: $height--card-product;
  width: $width--card-product;
  margin: $spacing--component;
  padding: $spacing--grid;
  border: 2px solid rgba(247,181,0, 1);
  box-shadow: 0px 1px 36px rgba(130,29,181, 1) inset;
  opacity: 1;
  background-image: radial-gradient(rgba(25, 92, 211, 1) 0%, rgba(55, 4, 53, 1) 100%);
  position: relative;
  &.discount {
    background-color: $brand-color--orange;
  }
}

.subCard {
  margin: $spacing--component 0;
  padding: $spacing--grid $spacing--component $spacing--component;
  background-color: $brand-color--brown-dark;
  border: 1px solid $brand-color--brown-border;
  box-shadow: 0px 1px 9px 1px $brand-color--brown-shadow inset;
}

.divider {
  width: $full-percent;
  height: 2px;
  background: transparent
    linear-gradient(
      270deg,
      $brand-color--brown 0%,
      $brand-color--yellow 49%,
      $brand-color--orange 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
}

.gameFrame {
  @include centeredFlexColumn;
  width: $full-percent;
  height: calc(100vh - 47px);
  padding: 0;
  background-color: $brand-color--black;
}

.playNow {
  width: $width--full-viewport;
  max-width: $width--full-viewport;
  padding: 0;
  margin: 0;
  position: relative;
}

.downloadCard {
  text-align: center;
}

.infoHeader {
  text-align: center;
  color: white;
}

.mainInfoHeader {
  @include subTitle;
  color: white;
  text-align: center;
}

.subInfoHeader {
  @include subTitle;
  color: white;
}

.mainHeader {
  @include mainTitle;
  color: $brand-color--yellow-header;
  text-align: center;
}

.subHeader {
  @include subTitle;
  color: $brand-color--yellow-header;
}

.textContainer {
  @include componentWidth;
  margin: $spacing--component;
}

.newsCard {
  width: $width--container;
  @media (max-width: $breakpoint--tablet) {
    width: 75%;
  }
  
  img {
    max-width: 100%
  }
}

.overImage {
  @include subTitle;
  font-family: "Mondo";
  color: $brand-color--black;
  margin: $spacing--component;
  text-align: center;
  @media (max-width: $breakpoint--tablet) {
    color: $brand-color--yellow-header;
    text-shadow: $text-shadow;
    margin: 0 $spacing--grid;
  }
}

.overTopImage {
  @include subTitle;
  font-family: "Mondo";
  color: #F7B500;
  margin: $spacing--component;
  text-align: center;
  @media (max-width: $breakpoint--tablet) {
    color: $brand-color--yellow-header;
    text-shadow: $text-shadow;
    margin: 0 $spacing--grid;
  }
  border-radius: 30px;
  opacity: 1;
  background-color: rgba(21,1,16, 0.4);
  padding: 11px 72px;
}

.overTopImageMobile {
  @include subTitle;
  font-family: "Mondo";
  color: #F7B500;
  margin: $spacing--component;
  text-align: center;
  @media (max-width: $breakpoint--tablet) {
    color: $brand-color--yellow-header;
    text-shadow: $text-shadow;
    margin: 0 $spacing--grid;
  }
  padding: 11px 72px;
}

.errorHeader {
  text-shadow: $text-shadow;
  margin-bottom: 0;
}

.errorSubHeader {
  font-family: "SofiaSans";
  font-size: $font-size--normal;
  color: $brand-color--yellow-text;
  button {
    color: $brand-color--green-bright;
  }
}

.productHeader {
  margin-top: $spacing--grid;
  opacity: 1;
  color: rgba(247,181,0,1);
  font-family: "Mondo";
  font-size: 32px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  line-height: 36px;
  width: 90%;
  top: 4%;
  position: absolute;
  z-index: 10;
}

.productInfoHeader {
  margin-top: $spacing--grid;
  opacity: 1;
  color: rgba(247,181,0,1);
  font-family: "Mondo";
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 36px;
  width: 80%;
  top: 10%;
  position: absolute;
}

.productInfoBody {
  margin-top: $spacing--grid;
  opacity: 1;
  color: white;
  font-family: "Mondo";
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 36px;
  width: 80%;
  top: 30%;
  position: absolute;
}


.priceHeader {
  opacity: 1;
  color: rgba(255,255,255,1);
  font-family: "Mondo";
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
  margin-top: $spacing--grid;
  bottom: 20%;
  position: absolute;
}

.buyButtonContainer {
  bottom: 3%;
  position: absolute;
}

.gemListHeader {
  text-align: center;
}