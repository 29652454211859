// Import global variables
@import "/src/styles/variables.scss";

.userData {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0;

  div {
    @include centeredFlexColumn;
    justify-content: flex-end;
    font-family: "Mondo";
  }

  .level {
    @include mainTitle;
    margin: 0;
  }

  .name {
    color: rgb(231, 202, 106);
    margin: 0;
    display: flex;
    flex-direction: row;
    text-align: right;
  }

  .gems {
    color: rgb(231, 202, 106);
    margin: 0;
    display: flex;
    flex-direction: row;
    text-align: right;
    padding-right: 40px;
  }
}

.userDataMobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: $breakpoint--tablet) {
    display: none;
  }

  div {
    @include centeredFlexColumn;
    justify-content: flex-end;
    margin: $spacing--grid $spacing--component;
    font-family: "Mondo";
  }

  .name {
    color: rgb(231, 202, 106);
    margin: 0;
    display: flex;
    flex-direction: row;
    text-align: right;
    margin-left: auto;
    margin-right: -20px;
  }

  .gems {
    color: rgb(231, 202, 106);
    display: flex;
    flex-direction: row;
    text-align: right;
    padding-right: 40px;
    margin-left: 20px;
  }
}