@font-face {
  font-family: "Mondo";
  src: url("./fonts/Mondo-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "SofiaSans";
  src: url("./fonts/SofiaSans-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Mondo", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
html, body {
  height: 100%;
}
canvas {
  display: block;
}
body {
  margin: 0;
}
#unity-container {
  width: 100%;
  height: 100%;
}
#unity-canvas {
  width: 100%;
  height: 100%;
  background: #000000;
}
#loading-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#unity-loading-bar {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#unity-logo {
  text-align: center;
}
#unity-logo img {
  max-width: 80%;
}
#unity-progress-bar-empty {
  width: 80%;
  height: 24px;
  margin: 10px 20px 20px 10px;
  text-align: left;
  border: 1px solid white;
  padding: 2px;
}
#unity-progress-bar-full {
  width: 0%;
  height: 100%;
  background: white;
}
.light #unity-progress-bar-empty {
  border-color: black;
}
.light #unity-progress-bar-full {
  background: black;
}

#unity-fullscreen-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 38px;
  height: 38px;
  background-size: contain;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.spinner {
  margin: 10px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: spinner-spin 1.1s infinite linear;
}
@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


