// Import global variables
@import "/src/styles/variables.scss";

.divider {
  margin: $spacing--grid 0;
  max-width: $width--container;
  @media (max-width: $breakpoint--tablet) {
    max-width: 90vw;
  }
}
