// Import global variables
@import "/src/styles/variables.scss";

.contentOverImage {
  @include centeredFlexColumn;
  position: absolute;
  z-index: 2;
  bottom: 0;
  text-align: center;
  width: $full-percent;
  padding-bottom: $spacing--component;
}

.logoOverImage {
  @include centeredFlexColumn;
  position: absolute;
  z-index: 2;
  top: 20px;
  text-align: center;
  width: $full-percent;
  padding-bottom: $spacing--component;
}

.divider {
  width: $full-percent;
  height: 40px;
}
