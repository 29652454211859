// Import global variables
@import "/src/styles/variables.scss";

.background {
  @include modalShape;
  background-color: rgba(0, 0, 0, 0.2);
  width: $width--full-viewport;
  height: 100vh;
  z-index: 0;
  position: absolute;
}

.modal {
  @include modalShape;
  position: fixed;
  width: $full-percent;
  height: fit-content;
  background: $brand-color--white;
  color: $brand-color--black;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalTitle {
  @include subTitle;
  margin: 0;
  padding: $spacing--grid;
  text-align: center;
}

.modalContent {
  @include basicText;
  padding: $spacing--grid;
  text-align: center;
}

.modalActions {
  @include centeredFlexColumn;
  margin-bottom: $spacing--grid;
  width: $full-percent;
  justify-content: space-between;
}

.actionsContainer {
  @include centeredFlexRow;
  justify-content: space-around;
}
