// Import global variables
@import "/src/styles/variables.scss";

a,
button {
  &.warning {
    @include basicButton;
    color: $brand-color--white;
    background: $brand-color--orange;
  }

  &.success {
    @include basicButton;
    color: $brand-color--white;
    background: url("../../public/images/ButtonBg.png");
    background-size:100% 100%;
    opacity: 1;
    font-family: "Mondo";
    text-align: center;
    white-space: nowrap
  }

  &.login {
    @include basicButton;
    color: $brand-color--white;
    background: url("../../public/images/ButtonBg.png");
    background-size:100% 100%;
    opacity: 1;
    font-family: "Mondo";
    text-align: center;
    white-space: nowrap;
    border-radius: 10px;
    font-size: $font-size--normal;
    padding: 12px 50px;
  }

  &.fullscreen {
    @include basicButton;
    color: $brand-color--white;
    background: url("../../public/images/fullscreenButton.png");
    background-size:100% 100%;
    opacity: 1;
  }

  &.closegame {
    @include basicButton;
    color: $brand-color--white;
    background: url("../../public/images/quitButton.png");
    background-size:100% 100%;
    opacity: 1;
  }

  &.info {
    @include basicButton;
    color: $brand-color--blue;
    background: $brand-color--white;
  }

  &.significant {
    font-size: $font-size--medium;
    margin: $spacing--component;
    @media (max-width: $breakpoint--tablet) {
      font-size: $font-size--medium;
    }
    @media (max-width: $breakpoint--mobile) {
      font-size: $font-size--normal;
    }
  }

  &.secondary {
    @include basicButton;
    color: $brand-color--green;
    background: $brand-color--white;
  }

  &.inferior {
    @include basicButton;
    color: $brand-color--white;
    background: $brand-color--ocra;
    font-size: $font-size--normal;
    width: fit-content;
  }

  &.textLink {
    all: unset;
    cursor: pointer;
    color: $brand-color--yellow;
    text-decoration: none;
    margin: $spacing--grid;

    &:hover {
      filter: brightness(65%);
    }

    &.selected {
      text-decoration: underline;
    }
  }

  &.navLink {
    @include navLink;
    @include plainLink;
    background-color: inherit;
    @media (min-width: $breakpoint--tablet) and (max-width: $breakpoint--desktop) {
      font-size: 1rem;
    }
  }

  &.mobileNavLink {
    @include navLink;
    background-color: inherit;
  }

  &.linkButton {
    @include plainLink;
    border: none;
    color: $brand-color--yellow-header;
  }

  .imageButton {
    max-height: 60px;
    margin: $spacing--grid;
  }

  &.iconButton {
    height: 40px;
    padding: 5px;
    margin: $spacing--grid;
    background-color: $brand-color--grey-light;
    border-radius: $spacing--grid;
  }
  &.logoutButton {
    @include basicButton;
    color: $brand-color--white;
    background: url("../../public/images/logoutButton.png");
    background-size:100% 100%;
    opacity: 1;
    height: 57px;
    width: 157px;
  }
  &.gameDataToggle {
    font-size: $font-size--small;
    padding: $spacing--grid;
    @media (min-width: $breakpoint--tablet) {
      display: none;
    }
  }
  &.disabled {
    pointer-events: none;
  }
  &.hideInMobile {
    @include hideInMobile;
  }
}

.storeButtons {
  a {
    margin: $spacing--grid;
  }
}
