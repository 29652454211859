// Import global variables
@import "/src/styles/variables.scss";

.navigation {
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: $breakpoint--tablet) {
    display: none;
  }
}

.navList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  list-style-type: none;
  
  li {
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    padding: 0 $spacing--component / 2;
    .navLink {
      @include navLink;
      font-size: 1rem;
      @media (min-width: $breakpoint--tablet) and (max-width: $breakpoint--desktop) {
      }
    }
  }
}

.navLogin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  list-style-type: none;
  padding: 0 7px;
  width: 100%;
}

.mobileNavigation {
  display: flex;
  width: 100%;
  justify-content: right;
  @media (min-width: $breakpoint--tablet) {
    display: none;
  }
}

.mobileNavList {
  @include centeredFlexColumn;
  list-style-type: none;
  padding: $spacing--app 0 0 0;

  li {
    .mobileNavLink {
      @include navLink;
    }
  }
}
