// Import global variables
@import "/src/styles/variables.scss";

.header {
  @include centeredFlexRow;
  justify-content: space-between;
  z-index: 10;
  padding: 0 1rem;
  color: $brand-color--white;
  background-color: rgb(33, 30, 29);
  height: $height--header-desktop;
  @media (max-width: $breakpoint--tablet) {
    height: $height--header-mobile;
  }
  span {
    @include centeredFlexColumn;
  }
}
